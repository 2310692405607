import validate from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqlite3@1_aqymfpi2vucis6l45fd7xkr5ou/node_modules/nuxt/dist/pages/runtime/validate.js";
import landing_45global from "/app/middleware/landing.global.ts";
import testing_45global from "/app/middleware/testing.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sqlite3@1_aqymfpi2vucis6l45fd7xkr5ou/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  landing_45global,
  testing_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts")
}